<template>
  <div class="patientDetail">
    <PatientDetail backRoute="DoctorPatientsList" />
    <PatientTreatmentList treatmentHistoryDetailRoute="DoctorPatientTreatmentDetail" />
  </div>
</template>

<script>
import PatientDetail from "@/views/components/patients/Detail2";
import PatientTreatmentList from "@/views/components/patients/PatientTreatmentList2";

export default {
  components: {
    PatientDetail,
    PatientTreatmentList
  },
  beforeRouteLeave(to, from, next) {
    const popup = document.getElementById("editPatientDetailsDrawer");
    const popup2 = document.getElementById("createNewPatientTreatment");

    if (popup && window.getComputedStyle(popup).display !== "none"){
      next(false);
    }
    else if (popup2 && window.getComputedStyle(popup2).display !== "none"){
      next(false);
    }
    else {
      next();
    }
  },
};
</script>
